import * as React from 'react'
import { NavLink } from 'components/NavLink'
import { Link } from 'gatsby'
import { Logo } from 'components/Logo'

const NotFoundPage = () => {
  const links = [
    { title: 'Home', href: '/' },
    { title: 'About', href: '/#about' },
    { title: 'FAQ', href: '/#faq' },
    { title: 'Events', href: '/#events' },
    { title: 'Donors', href: '/#donors' },
  ]

  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Armand Hurford for Mayor</span>
              <Logo />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-red-600">404</p>
              <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-red-600 hover:text-red-500"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
        <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="text-sm" aria-label="quick links">
            <div className="flex justify-center gap-x-6">
              {links.map(({ title, href }) => (
                <NavLink key={`footer-nav-link-${title}`} href={href}>
                  {title}
                </NavLink>
              ))}
            </div>
          </nav>
        </footer>
      </div>
    </>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
